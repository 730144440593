export default {
  beforeMount() {
    window.addEventListener('beforeunload', this.onBeforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },
  methods: {
    onBeforeUnload(e) {
      e.returnValue = false
    },
  },
}
