<template>
  <v-container class="px-0 pb-6">
    <v-form>
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-row class="mt-9 mb-9">
            <v-col cols="12">
              <h2 class="mb-6" v-text="$t('club.clubEnrollmentForm.intro.addTeams')" />
              <div class="d-flex">
                <v-icon class="mr-3" color="primary" size="24" v-text="'lightbulb_outline'" />
                <span>{{ $t('club.clubEnrollmentForm.addTeams.addCategoriesDescription') }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row v-for="(cohort, i) in form.cohorts" :key="cohort.listId">
            <v-col cols="12" class="d-flex justify-space-between align-center">
              <h3 v-text="`${$t('common.category')} ${i + 1}`" />
              <v-btn v-if="form.cohorts.length > 1"
                     class="w-auto" icon small color="primary"
                     @click="$confirm($t('club.clubEnrollmentForm.addTeams.sureWantDeleteCategory'))
                       .then(confirm => confirm && form.cohorts.splice(i, 1))"
              >
                <span>{{ $t('common.delete') }}</span>
                <v-icon dark class="mx-1">
                  delete_outline
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="8" class="py-1 px-3">
              <cohort-field
                v-model="cohort.id"
                :disabled-cohort-ids="form.cohorts.map(c => c.id)"
                :error-messages="$v.form.cohorts.$each[i].id.$error ? $t('validation.required') : ''"
              />
            </v-col>
            <v-col cols="12" sm="4" class="py-1 px-3">
              <v-select
                v-model="cohort.teamCount" :items="teamCountItems"
                :error-messages="$v.form.cohorts.$each[i].teamCount.$error ? $t('validation.required') : ''"
                :label="$t('club.clubEnrollmentForm.addTeams.teamCount')" append-icon="mdi-chevron-down" outlined dense
              />
            </v-col>
            <v-col v-if="i === form.cohorts.length - 1" cols="12">
              <v-btn class="w-auto" icon small color="primary" @click="form.cohorts.push({ id: null, teamCount: null, listId: Math.random() })">
                <v-icon dark class="mx-1" v-text="'add_box'" />
                <span>{{ $t('project.academy.categories.addCategory') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-9">
            <v-col cols="12">
              <v-checkbox
                v-model="form.compromiseMinPlayers" :label="$t('club.clubEnrollmentForm.addTeams.compromiseMinPlayers')"
                :error-messages="getFieldErrors('compromiseMinPlayers')"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import preventReload from '@/utils/mixins/preventReload'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'AddTeams',
  components: {
    CohortField: () => import('@/components/formFields/CohortField'),
  },
  mixins: [formUtils, preventReload],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    globalForm: { type: Object, required: true },
  },
  data() {
    return {
      form: {
        cohorts: this.globalForm.cohorts ?? [{ id: null, teamCount: null, listId: Math.random() }],
        compromiseMinPlayers: this.globalForm.compromiseMinPlayers ?? false,
      },
      teamCountItems: [...Array(9)].map((_, i) => i + 1),
    }
  },
  validations() {
    return {
      form: {
        cohorts: {
          required,
          $each: {
            id: { required },
            teamCount: { required },
          },
        },
        compromiseMinPlayers: { checked: val => val },
      },
    }
  },
  created: () => window.scrollTo(0, 0),
}
</script>
